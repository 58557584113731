/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Gist from "react-gist";

import CoverdashQuotes from "@coverdash/quotes";
import "@coverdash/quotes/lib/cjs/styles.css";

function App() {
  const [isOwnerAuthenticated, setIsOwnerAuthenticated] = React.useState(false);
  React.useEffect(() => {
    setIsOwnerAuthenticated(
      localStorage.getItem("isOwnerAuthenticated") === "true"
    );
  }, []);
  if (!isOwnerAuthenticated) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            Access restricted.
          </div>
          <div>
            <form
              action="/"
              onSubmit={(e) => {
                const password = e.target.password.value;

                if (password === "coverdashStaging2022!") {
                  localStorage.setItem("isOwnerAuthenticated", "true");
                }
              }}
            >
              <input
                type="password"
                id="password"
                style={{
                  padding: "0.5rem",
                  borderBottomWidth: "2px",
                  borderColor: "gray",
                }}
                placeholder="Password"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <iframe
        id="coverdash-quotes-component"
        // src={`https://quotes.coverdash.com?isDemo=true&license=f642f386-629c-48e5-bfe3-6ca0ae7bc26a`}
        src={`http://localhost:3001?isDemo=true&license=f642f386-629c-48e5-bfe3-6ca0ae7bc26a`}
        // scrolling="no"
        frameBorder="0"
      ></iframe> */}
      <CoverdashQuotes
        isDemo={true}
        allowScroll={false}
        license="f642f386-629c-48e5-bfe3-6ca0ae7bc26a"
        autoResize={true}
      />
      <div
        style={{
          width: "91%",
          maxWidth: "80rem",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "1rem",
        }}
      >
        <Gist id="1bba821989eedd7f5882bb5d261aa051" />
      </div>
    </>
  );
}

export default App;
